<template>
  <section class="admin-stat-container">
    <LayoutCard class="current-costs">
      <h6>Couts intervention :</h6>
      <CommonStatChip title="Cout année en cours" transparent color="dark">
        {{ statsStore.getBilling.currentYearCosts }} €</CommonStatChip
      >
      <CommonStatChip title="Cout M-1" transparent color="dark">
        {{ statsStore.getBilling.pastMonthCosts }} €</CommonStatChip
      >
      <CommonStatChip title="Cout M" transparent :arrow="getArrowDirection()" color="success">
        {{ statsStore.getBilling.currentMonthCosts }} €</CommonStatChip
      >
    </LayoutCard>
    <InputDateRange
      id="datepicker"
      label="Données pour la période"
      placeholder="Selectionnez une période : "
      v-model="statsStore.date"
    />
    <CommonParagraph title="Activité">
      <div class="stat-container">
        <CommonInsert title="Nombre d'interventions" column>
          <CommonStatChip size="large" filled transparent color="success" extend legend="Total">
            {{ statsStore.getActivity?.countInterventions }}
          </CommonStatChip>
          <div class="day-halfday">
            <CommonStatChip
              size="large"
              filled
              transparent
              color="dark"
              :legend="
                statsStore.getActivity.countInterventionsFullDay > 1 ? 'Journées' : 'Journée'
              "
            >
              {{ statsStore.getActivity?.countInterventionsFullDay }}
            </CommonStatChip>
            <CommonStatChip
              size="large"
              filled
              transparent
              color="dark"
              :legend="
                statsStore.getActivity.countInterventionsHalfDay > 1
                  ? 'Demi-journées'
                  : 'Demi-journée'
              "
            >
              {{ statsStore.getActivity?.countInterventionsHalfDay }}
            </CommonStatChip>
          </div>
        </CommonInsert>
        <LayoutCard>
          <template
            v-if="
              statsStore.getActivity?.countInterventionsFullDay === 0 &&
              statsStore.getActivity?.countInterventionsHalfDay === 0
            "
          >
            <p class="title mb-20">Répartition de l'activité par compétences</p>
            <LabelInfo> Aucune donnée disponible. </LabelInfo>
          </template>
          <template v-else>
            <div class="activity-container">
              <p>Répartition de l'activité par compétences</p>
              <InputSelect v-model="selectedUnit" :options="unitOptions" id="input-select" />
            </div>
            <div class="chart-container">
              <template v-if="statsStore.getActivity?.countInterventionsFullDay > 0">
                <CompositeChart
                  label="Journées"
                  displayOutlabels
                  :chartData="getChartData('full')"
                  :dataLabels="selectedUnit"
                  :separator="statsStore.getActivity?.countInterventionsHalfDay === 0"
                  :legend="statsStore.getActivity?.countInterventionsHalfDay === 0"
                />
              </template>
              <template v-if="statsStore.getActivity?.countInterventionsHalfDay > 0">
                <CompositeChart
                  label="Demi journées"
                  displayOutlabels
                  :chartData="getChartData('half')"
                  :dataLabels="selectedUnit"
                  :legend="true"
                  :separator="true"
                />
              </template>
            </div>
          </template>
        </LayoutCard>
      </div>
    </CommonParagraph>
    <div class="stat-container">
      <CommonParagraph title="Charge consultants">
        <LayoutCard class="consultant-costs">
          <CommonStatChip title="Cout moyen intervention" transparent color="dark">
            {{ statsStore.getBilling.averageInterventionCost }} €</CommonStatChip
          >
          <div class="costs-details">
            <p>Détail du coût :</p>
            <p>Honoraires : {{ statsStore.getBilling.interventionFees }} €</p>
            <p>Déplacement : {{ statsStore.getBilling.travelExpenses }} €</p>
            <p>Frais supplémentaires : {{ statsStore.getBilling.additionalFees }} €</p>
          </div>
          <CommonStatChip title="Cout moyen des frais" transparent color="dark">
            {{ statsStore.getBilling.averageChargeAmount }} €</CommonStatChip
          >
        </LayoutCard>
      </CommonParagraph>
      <CommonParagraph title="Zone">
        <CommonInsert title="Répartition du % de missions par zones">
          <template v-if="statsStore.getActivity?.countInterventions > 0">
            <CompositeChart
              label="Missions par zones"
              displayOutlabels
              :chartData="getZoneDistribution()"
              dataLabels="percentage"
              :legend="true"
              :separator="true"
            />
          </template>
          <template v-else>
            <LabelInfo> Aucune donnée pour cette période. </LabelInfo>
          </template>
        </CommonInsert>
      </CommonParagraph>
    </div>
    <CommonParagraph title="Zones à staffer">
      <CommonInsert
        title="Top 5 des départements les moins couverts"
        column
        v-if="statsStore.getUndercoveredDepartments.length > 0"
      >
        <ol class="ol">
          <li v-for="dpt in getUndercoveredDepartements()" :key="dpt.name" class="mb-10">
            {{ dpt.name }} ({{ dpt.postalCode }})
          </li>
        </ol>
      </CommonInsert>
      <CommonInsert title="Top 5 des départements les moins couverts" column v-else>
        <LabelInfo> Aucune donnée pour cette période. </LabelInfo>
      </CommonInsert>
    </CommonParagraph>
  </section>
</template>
<script setup lang="ts">
import CommonInsert from '@/components/common/CommonInsert.vue'
import CommonParagraph from '@/components/common/CommonParagraph.vue'
import CommonStatChip from '@/components/common/CommonStatChip.vue'
import CompositeChart from '@/components/composite/CompositeChart.vue'
import LabelInfo from './label/LabelInfo.vue'
import LayoutCard from '@/components/layout/LayoutCard.vue'
import { useStatsStore } from '@/stores/gae/store_stats'
import { onMounted, ref, watch } from 'vue'
import { FR_REGIONS } from '@/services/service_geo'
import InputDateRange from '@/components/common/input/InputDateRange.vue'
import InputSelect from '@/components/common/input/InputSelect.vue'

const statsStore = useStatsStore()
const selectedUnit = ref<'percentage' | 'value' | 'hours' | undefined>('percentage')

const unitOptions = [
  { value: 'percentage', label: 'Pourcentage' },
  { value: 'value', label: 'Nombre' },
  { value: 'hours', label: 'Heures' }
]
function getLabels() {
  const labels = statsStore.getActivityDistribution.map((d) => d.categoryName)
  return labels
}

function getColors() {
  return ['#7cb7ef', '#c19bee', '#738ce8', '#f9e471', '#9fd6b9']
}

function getZoneColors() {
  return ['#FDF0EC', '#F9D2C5', '#F5B49F', '#F19778', '#ED7952', '#E95B2B']
}

// Get array with indexes of lowest percentage to add 'A staffer' chip ( CURRENTLY REMOVED )
/* function getLowestPercentageIndexes() {
  const percentages = statsStore.getZoneDistribution.map((d) => d.percentage)
  const minPercentage = Math.min(...percentages)

  return percentages.reduce((lowestIndexes: number[], current, index) => {
    if (current === minPercentage) {
      lowestIndexes.push(index)
    }
    return lowestIndexes
  }, [])
} */

function getZoneDistribution() {
  const zoneLabels = statsStore.getZoneDistribution.map(
    (d) =>
      (d.zoneLevel === 0 ? 'Distanciel' : 'Zone ' + d.zoneLevel) +
      ' (' +
      d.interventionCount +
      ' missions)'
  )
  const chartData = {
    labels: zoneLabels,
    datasets: [
      {
        backgroundColor: getZoneColors(),
        data: statsStore.getZoneDistribution.map((d) => d.percentage)
      }
    ]
  }
  return chartData
}
function getChartData(type: 'full' | 'half') {
  const chartData = {
    labels: getLabels(),
    datasets: [
      {
        backgroundColor: getColors(),
        data: type === 'full' ? getFullDayData() : getHalfDayData()
      }
    ]
  }
  return chartData
}

function getFullDayData() {
  if (selectedUnit.value === 'hours') {
    return statsStore.getActivityDistribution.map((d) => d.countFullDaysHours)
  } else {
    return statsStore.getActivityDistribution.map((d) => d.countFullDays)
  }
}

function getHalfDayData() {
  if (selectedUnit.value === 'hours') {
    return statsStore.getActivityDistribution.map((d) => d.countHalfDaysHours)
  } else {
    return statsStore.getActivityDistribution.map((d) => d.countHalfDays)
  }
}

function getUndercoveredDepartements() {
  // Map postcode to region name
  const postalCodeToRegion = new Map()
  FR_REGIONS.forEach((region) => {
    postalCodeToRegion.set(region.code, region.name)
  })
  // Order departements by their position
  const orderedDepartements = statsStore.getUndercoveredDepartments?.sort(
    (a, b) => a.position - b.position
  )
  // Get departement name from postcode
  const departments = orderedDepartements?.map((d) => d.departmentCode)

  if (!departments || departments?.length === 0) return

  const departmentNames = departments.map((code) => ({
    name: postalCodeToRegion.get(code),
    postalCode: code
  }))

  return departmentNames
}

function getArrowDirection() {
  const { currentMonthCosts, pastMonthCosts } = statsStore.getBilling
  return currentMonthCosts === pastMonthCosts
    ? 'equal'
    : currentMonthCosts > pastMonthCosts
    ? 'up'
    : 'down'
}

// FetchAdminStats if dateFrom or dateTo change
watch(() => statsStore.date, statsStore.fetchAdminStats)

onMounted(() => {
  statsStore.fetchAdminStats()
})
</script>

<style scoped lang="scss">
.admin-stat-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
}

.current-costs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}

.current-costs > div {
  width: fit-content;
}

.stat-container {
  display: flex;
  gap: 50px;
}

.consultant-costs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chart-container {
  display: flex;
  gap: 20px;
}

.costs-details {
  line-height: 170%;
  font-family: 'Roboto';
  opacity: 0.75;
  color: var(--vc-dark);
}

.costs-details > p:first-of-type {
  font-weight: 600;
  margin-bottom: 5px;
}

.day-halfday {
  display: flex;
  gap: 50px;
}

.activity-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
}

.activity-container > p,
.title {
  font-size: 18px;
  font-weight: 700;
}

.common-card {
  height: fit-content;
}

ol {
  counter-reset: item;
  padding: 0px;
}

li {
  counter-increment: item;
  font-weight: 400;
  color: var(--vc-dark);
}

li:before {
  margin-right: 10px;
  content: counter(item) '.';
  color: black;
  text-align: center;
  display: inline-block;
  font-weight: 500;
  color: var(--vc-dark);
}

h6 {
  text-decoration: underline;
}
</style>
