<template>
  <form class="form-intervention">
    <InputSkills
      @on-change="searchConsultant"
      id="intervention-skills"
      label="Intervention"
      v-model="form.skills"
    />
    <InputBoolean label="Distanciel" id="intervention-remote" v-model="form.remote" />
    <InputCommon
      id="intervention-title"
      label="Complément d'information"
      v-model="form.complement"
    />
    <LayoutTwoColumn>
      <InputDate
        id="intervention-date"
        label="Date"
        required
        v-model="v$.date.$model"
        :errors="v$.date.$errors"
        placeholder="21 septembre 2023"
        @update:model-value="debounceSearch"
      />
      <LayoutTwoColumn>
        <InputTime
          id="intervention-start"
          label="Heure de début"
          placeholder="14:00"
          required
          :errors="v$.start.$errors"
          v-model="v$.start.$model"
        />
        <InputTime
          id="intervention-end"
          label="Heure de fin"
          placeholder="17:00"
          required
          :errors="v$.end.$errors"
          v-model="v$.end.$model"
        />
      </LayoutTwoColumn>
    </LayoutTwoColumn>
    <InputSelect
      id="intervention-day"
      label="Journée, Demi-journée"
      :options="[
        { label: 'Journée', value: 'full_day' },
        { label: 'Demi-journée', value: 'half_day' }
      ]"
      clearable
      v-model="form.day_part"
    />
    <InputArea
      id="intervention-sponsor"
      label="Commanditaire"
      placeholder="Texte libre..."
      v-model="form.sponsor"
    />
    <InputCommon
      id="intervention-client"
      label="Bénéficiaire"
      placeholder="Etablissement / Site"
      v-model="form.client"
    />
    <LayoutTwoColumn>
      <InputArea
        id="intervention-commercial-client"
        label="Contact client"
        placeholder="Texte libre..."
        v-model="form.contact_commercial"
      />
      <InputArea
        id="intervention-contact"
        label="Contact(s) sur place"
        placeholder="Texte libre..."
        v-model="form.contact"
      />
      <InputCommon
        id="intervention-address"
        label="Adresse"
        placeholder="3 Rue de la Division Leclerc"
        icon="MapPin"
        v-model="form.address"
      />
      <LayoutTwoColumn>
        <InputCommon
          id="intervention-postcode"
          label="CP"
          required
          placeholder="67000"
          v-model="v$.postcode.$model"
          :errors="v$.postcode.$errors"
          @update:model-value="debounceSearch"
        />
        <InputCommon
          id="intervention-city"
          label="Ville"
          placeholder="Strasbourg"
          v-model="form.city"
        />
      </LayoutTwoColumn>
    </LayoutTwoColumn>
    <InputQuill
      id="intervention-comment"
      label="Détails de la mission"
      placeholder="Détails de la mission..."
      v-model="form.comment"
    ></InputQuill>
    <InputConsultant
      v-model="form.consultant"
      id="intervention-consultant"
      placeholder="Sélectionnez un consultant..."
    />
    <InputSelect
      id="intervention-status-mission"
      label="Fiche Mission"
      :options="getMissionStatusArray()"
      :disabled="form.mission_status === 'documents_uploaded'"
      v-model="form.mission_status"
      placeholder="Incomplète, Demande d'infos"
    />
    <InputSelect
      id="intervention-status-client"
      label="Statut Client"
      :options="getClientStatusArray()"
      v-model="form.client_status"
      placeholder="Option, Validé, Annulé"
    />
    <LayoutTwoColumn>
      <InputSelect
        id="intervention-gae-refered"
        label="Référent Pôle Formation"
        :options="appdata.getAdminsOptions"
        v-model="form.gae_refered"
        placeholder="Sélectionner un Référent..."
      />
      <InputSelect
        id="intervention-gae-commercial"
        label="Référent Commercial"
        :options="appdata.getAdminsOptions"
        v-model="form.gae_commercial"
        placeholder="Sélectionner un commercial"
      />
    </LayoutTwoColumn>
    <InputFile id="intervention-document" label="Documents" v-model="form.files" accept=".pdf" />
    <LayoutTwoColumn>
      <InputSelect
        id="intervention-zone"
        label="Zone"
        :options="zoneOptions"
        v-model="form.zone"
        placeholder="Forcer la zone"
      />
      <InputBoolean id="intervention-sent" label="Pochette envoyée" v-model="form.sent" />
    </LayoutTwoColumn>
    <InputCommon
      id="intervention-additional-fees"
      label="Frais supplémentaires"
      v-model="form.additional_fees"
    />
  </form>
</template>

<script setup lang="ts">
import InputArea from '@/components/common/input/InputArea.vue'
import InputQuill from '@/components/common/input/InputQuill.vue'
import InputBoolean from '@/components/common/input/InputBoolean.vue'
import InputCommon from '@/components/common/input/InputCommon.vue'
import InputConsultant from '@/components/common/input/InputConsultant.vue'
import InputDate from '@/components/common/input/InputDate.vue'
import InputFile from '@/components/common/input/InputFile.vue'
import InputSelect from '@/components/common/input/InputSelect.vue'
import InputSkills from '@/components/common/input/InputSkills.vue'
import InputTime from '@/components/common/input/InputTime.vue'
import LayoutTwoColumn from '@/components/layout/LayoutTwoColumn.vue'
import { getTimeModelFromString } from '@/services/service_date'
import { getMissionStatusArray, getClientStatusArray } from '@/services/service_intervention'
import { useAppData } from '@/stores/gae/store_appdata'
import { useUserStore } from '@/stores/gae/store_user'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { useDebounceFn } from '@vueuse/shared'
import type { ConsultantSearchParams } from 'types/api/consultant'
import type { Intervention, InterventionForm } from 'types/api/intervention'
import { reactive, ref, watch } from 'vue'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

const user_store = useUserStore()
const appdata = useAppData()
const intervention_date = ref<Date>()

const zoneOptions: SelectOption[] = [
  { value: '1', label: 'Zone 1' },
  { value: '2', label: 'Zone 2' },
  { value: '3', label: 'Zone 3' },
  { value: '4', label: 'Zone 4' },
  { value: '5', label: 'Zone 5' }
]

function getData() {
  return form
}

function getValidation() {
  return v$.value.$validate()
}

function setData(data: InterventionForm) {
  Object.assign(form, data)
  searchConsultant()
}

const intervention_id = ref<number>()

function load(intervention: Intervention) {
  intervention_date.value = new Date(intervention.date)
  intervention_id.value = intervention.id

  setData({
    complement: intervention.complement,
    date: new Date(intervention.date),
    start: getTimeModelFromString(intervention.startTime),
    end: getTimeModelFromString(intervention.endTime),
    day_part: intervention.dayPart,
    skills: [...intervention.skills], // needs to be copied to not write on the store?
    client: intervention.customer ?? '',
    sponsor: intervention.backer ?? '',
    contact_commercial: intervention.commercialCustomerContact ?? '',
    contact: intervention.onSiteContact ?? '',
    address: intervention.address ?? '',
    postcode: intervention.postcode ?? '',
    city: intervention.city ?? '',
    consultant: intervention.consultant
      ? {
          id: intervention.consultant?.id ?? 0,
          acronym: intervention.consultant?.acronym ?? '',
          availability: intervention.consultant?.availability ?? false,
          name: intervention.consultant?.name ?? '',
          zone: intervention.consultant?.zone ?? 0,
          has_refused: false,
          has_skills: false
        }
      : undefined,
    mission_status: intervention.missionStatus,
    client_status: intervention.customerStatus,
    gae_refered: intervention.referent?.id.toString(),
    gae_commercial: intervention.salesperson?.id.toString(),
    pack: undefined,
    sent: intervention.isDocumentFolderSent,
    files: intervention.files,
    comment: intervention.comment ?? '',
    zone: intervention.zone?.level.toString() ?? '',
    additional_fees: intervention.additionalFees?.toString() ?? '',
    remote: intervention.isRemote
  })
}

defineExpose({ getData, setData, load, getValidation })

const form = reactive<InterventionForm>({
  complement: '',
  date: undefined,
  start: undefined,
  end: undefined,
  day_part: undefined,
  skills: [],
  client: '',
  sponsor: '',
  contact_commercial: '',
  contact: '',
  address: '',
  postcode: '',
  city: '',
  consultant: undefined,
  mission_status: undefined,
  client_status: undefined,
  gae_refered: undefined,
  gae_commercial: undefined,
  pack: undefined,
  sent: false,
  remote: false,
  files: undefined,
  comment: '',
  zone: '',
  additional_fees: ''
})

const postcode_rule = (v: string) => (v.length == 2 || v.length == 5) && !isNaN(parseInt(v))
const rules = {
  date: {
    required: helpers.withMessage('Le champ est requis.', required)
  },
  start: {
    required: helpers.withMessage('Le champ est requis.', required)
  },
  end: {
    required: helpers.withMessage('Le champ est requis.', required)
  },
  postcode: {
    required: helpers.withMessage('Le champ est requis.', required),
    postcode_rule: helpers.withMessage('Le code postal est invalide', postcode_rule)
  }
}

const v$ = useVuelidate(rules, form as any)

const debounceSearch = useDebounceFn(searchConsultant, 1000)
function searchConsultant() {
  if (form.skills.length === 0 && !form.date && !form.postcode) {
    user_store.clearSearch()
    return
  }

  const params: ConsultantSearchParams = {}
  if (form.skills.length > 0) {
    params.skills = form.skills.map((s) => s.id).join(';')
  }

  if (form.date) {
    params.date = form.date.toISOString()
  }

  if (form.postcode) {
    params.postcode = form.postcode
  }

  if (intervention_id.value) {
    params.intervention = intervention_id.value
  }

  user_store.search(params)
}
</script>

<style scoped>
.form-intervention {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
